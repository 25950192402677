<template>


    <div class="section-content">
        <div class="primary-section-content">
            <div class="main-section-heading">
                <div>

                    <h1 class="main-section-header">Manage QuickBooks Access</h1>
                    <p>Connect to QuickBooks to import your payments.</p>
                </div>
            </div>





        <div class="content-view">
            <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>
            <status @resetStatus="successClear($options.name)" v-if="successHas($options.name)" :message="successGet($options.name)" status="success"></status>
            <div class="form-section">


                <div class="horizontal-form-row" v-show="!loaded">
                    <loader color="#00b2ce" size="20px" class="inline-loader" loading></loader>
                </div>


                <div class="horizontal-form-row" v-show="!qbConfigured && loaded">
                    <div class="horizontal-form-label settings">
                        <label>Connect To QuickBooks:</label>
                        <p class="subdued">You are not currently connected to QuickBooks. Click the button to set it up.</p>
                    </div>
                    <div class="form-input-container text-right">
                        <img @click="connect" src="/img/quickbooks/C2QB_green_btn_lg_default.png" alt="" class="link-qb" style="width: 200px;">
                    </div>
                </div>



                <div class="horizontal-form-row" v-show="qbConfigured && loaded">
                    <div class="horizontal-form-label settings">
                        <label>{{ qbCompany.CompanyName }}</label>
                        <p class="subdued">Is successfully connected to QuickBooks.</p>
                    </div>
                    <div class="form-input-container text-right">
                        <a @click="disconnect" class="disconnect btn btn-std"> Disconnect From QuickBooks</a>
                    </div>
                </div>



                <div class="horizontal-form-row" v-show="qbConfigured && loaded">
                    <div class="horizontal-form-label settings">
                        <label>Income Account</label>
                        <p class="subdued">Enter the account where payments should be entered.</p>
                    </div>
                    <div class="form-input-container text-right">
                        <div style="max-width: 350px; width: 100%; float: right;">
                            <dropdown
                                :options="getAccountNames"
                                :value="accountName('qbIncomeAccount')"
                                @input="setAccount('qbIncomeAccount', $event)"
                            ></dropdown>
                        </div>
                    </div>
                </div>


                <div class="horizontal-form-row" v-show="qbConfigured && loaded">
                    <div class="horizontal-form-label settings">
                        <label>Enter Prepayment Liability Account</label>
                        <p class="subdued">Enter the liability account where prepayments or over payments should be sent.</p>
                    </div>
                    <div class="form-input-container text-right">
                        <div style="max-width: 350px; width: 100%; float: right;">
                            <dropdown
                                :options="getAccountNames"
                                :value="accountName('qbPrepaymentLiability')"
                                @input="setAccount('qbPrepaymentLiability', $event)" ></dropdown>
                        </div>
                    </div>
                </div>


                <div class="horizontal-form-row" v-show="qbConfigured && loaded">
                    <div class="horizontal-form-label settings">
                        <label>Enter Security Deposit Liability Account</label>
                        <p class="subdued">Enter the liability account where security deposits should be sent.</p>
                    </div>
                    <div class="form-input-container text-right">
                        <div style="max-width: 350px; width: 100%; float: right;">
                            <dropdown
                                :options="getAccountNames"
                                :value="accountName('qbDepositLiability')"
                                @input="setAccount('qbDepositLiability', $event)"></dropdown>
                        </div>
                    </div>

                </div>



                <div class="horizontal-form-row" v-show="qbConfigured && loaded">
                    <div class="horizontal-form-label settings">
                        <label>Sales Tax Rate</label>
                        <p class="subdued"> Enter the sales tax rate that you would like to use.  Set this up in QuickBooks.  When set, this will override other tax settings.</p>
                    </div>
                    <div class="form-input-container text-right">
                        <div style="max-width: 350px; width: 100%; float: right;">
                            <dropdown
                                :options="getSalesTaxAmounts"
                                :value="salesTax"
                                @input="setSalesTax"
                            ></dropdown>
                        </div>
                    </div>
                </div>



                <div class="horizontal-form-row" v-show="qbConfigured && loaded">
                    <div class="horizontal-form-label settings">
                        <button
                            :disabled="isLoading($options.name)"
                            @click="save"
                            class="primary-btn w-button">Save Settings</button>
                        <span v-show="isLoading($options.name)" >
                            <loader color="#00b2ce" size="20px" class="inline-loader"></loader>
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <!--<modal v-if="1 == 2" allow-click-away="true" @close="closeWindow">-->
            <!--<h3 slot="header">Delete Key</h3>-->
            <!--<div slot="body">-->
                <!--<div class="container-fluid">-->
                    <!--<div class="row">-->
                        <!--<div class="col-xs-12 ">-->
                            <!--<p>Are you sure you want to delete this key? All websites using this key will lose access to your data.</p>-->
                        <!--</div>-->
                    <!--</div>-->
                    <!--<div class="modal-footer">-->
                        <!--<button class="btn btn-danger" @click="deleteConfirm">Delete</button>-->
                        <!--<button class="btn btn-std" @click="closeWindow">Cancel</button>-->
                    <!--</div>-->
                <!--</div>-->
            <!--</div>-->
        <!--</modal>-->


        </div>
    </div>
</template>

<script type="text/babel">
    import Modal from '../assets/Modal.vue';
    import Status from '../includes/Messages.vue';
    import Loader from '../assets/CircleSpinner.vue';
    import api from '../../assets/api.js';
    import Dropdown from '../assets/Dropdown.vue';

    export default {
        name: "QuickBooksSettings",
        data() {
            return {
                category: 'quickbooks',
                qbCompany: {},
                qbConfigured: false,
                qbAccounts: [],
                qbTaxCodes: [],
                loaded: false,
                settings:{
                    qbIncomeAccount: '',
                    qbDepositLiability: '',
                    qbPrepaymentLiability: '',
                    qbTaxCode: ''
                }
            }
        },
        components:{
            Modal,
            Loader,
            Status,
            Dropdown
        },
        created(){
            intuit.ipp.anywhere.setup({
                grantUrl: window.location.protocol + '//' + window.location.hostname + '/settings/link-qb',
                datasources: {
                    quickbooks : true,  // set to false if NOT using Quickbooks API
                    payments : false    // set to true if using Payments API
                }
            });
            this.fetchData();

        },
        computed:{
            getAccountNames(){
                if(!this.qbAccounts) return false;
                return this.qbAccounts.map(a => {
                    return a.Name;
                })
            },

            getSalesTaxAmounts(){
                if(!this.qbTaxCodes) return false;
                return this.qbTaxCodes.map(a => {
                    return a.Name;
                })
            },
            salesTax(){
                if(!this.qbTaxCodes) return false;
                return this.qbTaxCodes.filter(a => {
                    return a.Id == this.settings.qbTaxCode;
                }).map(a => {
                    return a.Name;
                })[0]
            }
        },
        filters:{
            formatDate(date){
                return moment(date).format('MM/DD/YYYY HH:mm:ss')
            }
        },
        methods:{
            accountName(category){
                if(!this.qbAccounts.length) return false;
                return this.qbAccounts.filter(a => {
                    return a.Id == this.settings[category];
                }).map(a => {
                    return a.Name;
                })[0]
            },
            setAccount(category, name){
                if(!this.qbAccounts.length) return false;
                this.settings[category] = this.qbAccounts.filter(a => {
                    return a.Name == name;
                }).map(a => {
                    return a.Id;
                })[0];

            },
            setSalesTax(name){
                this.settings.qbTaxCode = this.qbTaxCodes.filter(a => {
                    return a.Name == name;
                }).map(a => {
                    return a.Id;
                })[0];

            },
            closeWindow(){
                this.showEdit = false;
                this.showDelete = false;
                this.showAdd = false;
                this.selected = {};
            },
            fetchData(){

                api.get(this, api.SETTINGS_GET_QUICKBOOKS).then(r => {
                    this.settings = r.settings;
                    this.qbCompany = r.qbCompany;
                    this.qbConfigured = r.qbConfigured;
                    this.qbAccounts = r.qbAccounts;
                    this.qbTaxCodes =  r.qbTaxCodes;
                    this.loaded = true;
                });
            },
            disconnect(){
                this.loaded = false;
                api.get(this, api.SETTINGS_QB_DISCONNECT).then(r => {
                    this.fetchData();
                });
            },
            connect(c){
                intuit.ipp.anywhere.controller.onConnectToIntuitClicked();
            },
            save(){
                var save = {
                    category: this.category,
                    data: this.settings
                };
                api.post(this, api.SETTINGS, save).then(r => {
                    console.log("RRRR", r);
                    console.log("$options", this.$options.name);
                    this.successSet(this.$options.name, "Quickbooks settings updated successfully.");
                });
            },
        }
    }
</script>

<style>
    .subdued {
        color: #a5adb4;
        line-height: 18px;
    }
</style>
